import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import Web3Token from 'web3-token';
import Navigation from "../components/navigation";
import Header from "../components/header";
import Input from "../components/input";
import Dropdown from "../components/dropdown";
import TextArea from "../components/text-area";
import Form from "../components/form";
import Layout from "../components/layout";

import './Submit.css';

// API Imports
import { _search } from "../_api"

import Footer from "../components/footer";
import { Button } from "../components/form/styles";

const projectCategories = [
  {  value: 'none', label: 'Pick a Category' },
  {  value: 'community', label: 'Community' },
  {  value: 'economic design', label: 'Economic Design' },
  {  value: 'economy tools', label: 'Economy Tools' },
  {  value: 'developer tools', label: 'Developer Tools' },
  {  value: 'developer resources', label: 'Developer Resources' },
  {  value: 'ecosystem', label: 'Ecosystem' },
  {  value: 'finance', label: 'Finance' },
  {  value: 'platform', label: 'Platform' },
  {  value: 'security', label: 'Security' },
  {  value: 'other', label: 'Other' }
]

const blockchainOptions = [
  {  value: 'none', label: 'Pick a Blockchain' },
  {  value: 'algorand', label: 'Algorand' },
  {  value: 'avalanche', label: 'Avalanche' },
  {  value: 'binanceSmartChain', label: 'Binance Smart Chain' },
  {  value: 'cardano', label: 'Cardano' },
  {  value: 'celo', label: 'Celo' },
  {  value: 'cosmos', label: 'Cosmos' },
  {  value: 'ethereum', label: 'Ethereum' },
  {  value: 'fantom', label: 'Fantom' },
  {  value: 'flow', label: 'Flow' },
  {  value: 'hathor', label: 'Hathor' },
  {  value: 'harmony', label: 'Harmony' },
  {  value: 'kardiaChain', label: 'KardiaChain' },
  {  value: 'kusama', label: 'Kusama' },
  {  value: 'lamden', label: 'Lamden' },
  {  value: 'litecoin', label: 'Litecoin' },
  {  value: 'monero', label: 'Monero' },
  {  value: 'near', label: 'Near' },
  {  value: 'polkadot', label: 'Polkadot' },
  {  value: 'polygon', label: 'Polygon' },
  {  value: 'ronin', label: 'Ronin' },
  {  value: 'solana', label: 'Solana' },
  {  value: 'starkNet', label: 'StarkNet' },
  {  value: 'terra', label: 'Terra' },
  {  value: 'tezos', label: 'Tezos' },
  {  value: 'tomochain', label: 'Tomochain' },
  {  value: 'tron', label: 'Tron' },
  {  value: 'waves', label: 'Waves' },
  {  value: 'wax', label: 'WAX' },
  {  value: 'zilliqa', label: 'Zilliqa' },
  {  value: 'zkSync', label: 'zkSync' },
  {  value: 'other', label: 'Other' }
];

const opensourceOptions = [
  { value: 'none', label: 'Is your project opensource?' },
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'partially', label: 'Partially' },
  { value: 'other', label: 'Other' }
];

const licenseOptions = [
  { value: 'none', label: 'If opensource, what License is your project under?' },
  { value: 'apache', label: 'Apache 2.0' },
  { value: 'eclipse', label: 'Eclipse 2.0' },
  { value: 'freeBSD', label: 'FreeBSD' },
  { value: 'gpl', label: 'GPL' },
  { value: 'isc', label: 'ISC' },
  { value: 'lgpl', label: 'LGPL' },
  { value: 'mit', label: 'MIT' },
  { value: 'mozilla', label: 'Mozilla 2.0' },
  { value: 'other', label: 'Other' }
];

const Submit = () => {
  const [ isLoggedin, setLoggedin ] = useState(false);
  const [selectDInput, setSelectDInput] = useState({
    applicantType: ""
  });
  const [selectInput, setSelectInput] = useState('');
  const [isKeyReleased, setIsKeyReleased] = useState(false)
  const [tags, setTags] = useState([]);

  const [
    search,
    setSearch,
  ] = useState({
    public_address: "",
    title: "",
    description: "",
    category: 0,
    categoryName: "",
    link: "",
    keywords: [],
    blockchains: "",
    opensource: "",
    license: "",
    improvements: "",
  });

  useEffect(() => {
    const authToken = Cookies.get('g7-auth');
    if(authToken) {
      setLoggedin(true)
    }
  }, []);

  const onChange = (e) => {
    const { value } = e.target;
    setSelectInput(value);
  };

  const changeHandler = (e) => {
    //this.setState({ part_id: e ? e.value : '' });
    console.log(selectDInput)
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = selectInput.trim();
    if(tags.length > 4) {
      alert("a maximum of 5 keywords are allowed")
      return
    }

    if (key === 'Enter' && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags(prevState => [...prevState, trimmedInput]);
      setSelectInput('');
    }

    if (key === "Backspace" && !selectInput.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setSelectInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }

  const deleteTag = (index) => {
    setTags(prevState => prevState.filter((tag, i) => i !== index))
  }

  const createSearchEntry = async () => {

    // if (!isLoggedin) {
    //   alert('you need to re-sign your transaction')
    //   return
    // }

    // const authToken = Cookies.get('g7-auth');
    // const { token, signed_msg } = JSON.parse(authToken)
    // const { address } = await Web3Token.verify(signed_msg);
    // search.public_address = address

    const token = ""
    search.keywords = tags

    // create search entry via the api
    const resp = _search.createSearchEntry(search, token)
    resp.then((r) =>{
      if(r.status === 201 || r.status === 200){
          return r.json()
      }
    }).then(() => {
      alert("search entry submitted successfully")
      logout()
    }).catch(() => {
      alert('search entry submission error')
    })
  }

  const logout = () => {
    Cookies.remove('g7-auth')
    setLoggedin(false)
    window.location.reload();
  }

  return (
    <>
      <Navigation />
      <Header
        title="G7 Engine"
        description="Finally, a tool that is designed to help developers understand what technologies and tools are currently being developed and who is developing them."
      />
      <Layout>
        <Form>
          <Input
            label="Name"
            placeholder="Name of your Solution"
            value={search.discord || ''}
            onChange={e => {
              setSearch(prevState => {
                return { ...prevState, title: e.target.value }
              });
            }}
          />

          <Input
            label="Link"
            placeholder="Link to your Solution"
            value={search.email || ''}
            onChange={e => {
              setSearch(prevState => {
                return { ...prevState, link: e.target.value }
              });
            }}
          />

          <TextArea
            label="Description"
            placeholder="Description of your Solution"
            value={search.description}
            onChange={e => {
              setSearch(prevState => {
                return { ...prevState, description: e.target.value }
              });
            }}
          />

          <Dropdown
            label="Project Category"
            defaultValue={projectCategories}
            options={projectCategories}
            onChange={(e) => {
              setSearch(prevState => {
                return {
                  ...prevState,
                  categoryName: e.value
                };
              });
            }}
          />

          <Dropdown
            label="Blockchains"
            defaultValue={blockchainOptions}
            options={blockchainOptions}
            onChange={(e) => {
              setSearch(prevState => {
                return {
                  ...prevState,
                  blockchains: e.value
                };
              });
            }}
          />

          <Dropdown
            label="Opensource?"
            defaultValue={opensourceOptions}
            options={opensourceOptions}
            onChange={(e) => {
              setSearch(prevState => {
                return {
                  ...prevState,
                  opensource: e.value
                };
              });
            }}
          />

          <Dropdown
            label="License"
            defaultValue={licenseOptions}
            options={licenseOptions}
            onChange={(e) => {
              setSearch(prevState => {
                return {
                  ...prevState,
                  license: e.value
                };
              });
            }}
          />

          <div className="container">
            {tags.map((tag, index) => (
              <div className="tag" key={index}>
                {tag}
                <button onClick={() => deleteTag(index)}>x</button>
              </div>
            ))}
            <input
              value={selectInput}
              placeholder="Enter a keyword"
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={onChange}
            />
          </div>

          <TextArea
            label="Help us improve the dataset"
            placeholder="Did me miss something? Let us know how you would improve the search engine."
            value={search.improvements}
            onChange={e => {
              setSearch(prevState => {
                return { ...prevState, improvements: e.target.value }
              });
            }}
          />

          <Button onClick={createSearchEntry}>Submit</Button>
        </Form>
      </Layout>

      <Footer />
    </>
  );
};

export default Submit;
