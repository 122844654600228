import search from './api.search';
import user from './api.users';

export const _search = search;
export const _user = user;

const models = {
  _search,
  _user,
};

export default models;