import styled from "styled-components";

export const Container = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Label = styled.label`
  font-size: 13px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.secondary};
`;

export const Error = styled.label`
  font-size: 13px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.accent};
`;