import Navigation from "../components/navigation";
import Hero from "../components/hero";

import Footer from "../components/footer";

const Home = () => {
  return (
    <>
      <Navigation />
      <Hero />
      <Footer />
    </>
  );
};

export default Home;
