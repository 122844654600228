import { useEffect, useState } from 'react';
import AutoSuggest from "react-autosuggest";
import "./styles.css";

// API Imports
import { _search } from "../../_api"

// Relative Imports
import {
  Container,
  Content,
} from "./styles";

//const lowerCasedCompanies = companies.map(language => language.toLowerCase());

const Hero = () => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [findings, setFindings] = useState([]);

  useEffect(() => {
    _search.getAutoSuggest().then((v) => {
      setKeywords(v.data)
    })
  }, [])

  function getSuggestions(value) {
    return keywords.filter(language =>
      language.startsWith(value.trim().toLowerCase())
    );
  }

  const setSearchValue = (value) => {
    _search.getSearch(value).then((v) => {
      setFindings(v.data)
    })
  }

  const parseKeywords = (keywords) => {
    let _k = ``
    for (let k in keywords) {
      _k += keywords[k] + ', '
    }

    _k = _k.slice(0, -2);
    let _str = _k.replace('%20', ' ')

    return _str
  }

  const Result = ({results}) => {
    if (results && results.length > 0) {
      return results.map((r, i) => {
        return (
          <div className="searchResults" key={i}>
            <div>
              <a href={r.link}>{r.title}</a>
            </div>
            <div>
              {r.description}
            </div>
            <div className="parentDiv">
              <div className="childDiv">
                <div>Opensource</div>
                <div>{r.opensource}</div>
              </div>
              <div className="childDiv">
                <div>License</div>
                <div>{r.license}</div>
              </div>
              <div className="childDiv">
                <div>Blockchains</div>
                <div>{r.blockchains}</div>
              </div>
            </div>
            <div className="keywordParentDiv">
              <div className="keywordChildDiv">
                <div>{parseKeywords(r.keywords)}</div>
              </div>
            </div>
          </div>
        )
      });
    }

    return <div>nothing found</div>;
  }

  return (
    <Container>
      <Content>
        <AutoSuggest
          suggestions={suggestions}
          onSuggestionsClearRequested={() => setSuggestions([])}
          onSuggestionsFetchRequested={({ value }) => {
            setValue(value);
            setSuggestions(getSuggestions(value));
          }}
          onSuggestionSelected={(_, { suggestionValue }) =>
            setSearchValue(suggestionValue)
          }
          getSuggestionValue={suggestion => suggestion}
          renderSuggestion={suggestion => <span>{suggestion}</span>}
          inputProps={{
            placeholder: "Type 'c'",
            value: value,
            onChange: (_, { newValue, method }) => {
              setValue(newValue);
            }
          }}
          highlightFirstSuggestion={true}
        />
        <Result results={findings} />
      </Content>

    </Container>
  );
};

export default Hero;
