const createWaitList = () => {

  const getAutoSuggest = async () => {
    const url = process.env.REACT_APP_API_URL+'/v1/engine/suggest'
    const response = await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        }
    });
    return await response.json();
  };

  const getSearch = async (keyword) => {
    const url = process.env.REACT_APP_API_URL+'/v1/engine/search/'+keyword
    const response = await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        }
    });
    return await response.json();
  };

  const createSearchEntry = async (search, token) => {
    const url = process.env.REACT_APP_API_URL+'/v1/engine/admin-search'
    return await fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
      body: JSON.stringify({
        search
      }),
    });
  }

  return {
    createSearchEntry,
    getAutoSuggest,
    getSearch,
  };

}

export default createWaitList();