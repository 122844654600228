// Relative Imports
import { Container, Title, Description, Route } from "./styles";

const Form = ({ children }) => {
  return (
    <Container>
      <Title>Add your project to the G7 Engine</Title>
      <Description>
        The G7 Engine is a search engine designed specifically for project developers, designers and managers.
        If you have ever wondered who was working on what, we got you. Add your project so others can find you.
      </Description>
      {children}
    </Container>
  );
};

export default Form;
